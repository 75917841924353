import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";

// action types
export const GET_CLAIMS = "getClaims";
export const GET_LEAVES = "getLeaves";
export const GET_CUSTOMERS = "getCustomersCount";
export const GET_PROJECTS = "getProjectData";
export const GET_MEETINGS = "getMeetingData";
export const GET_SALES = "getSalesData";
export const GET_CLOSED_LEADS = "getClosedLeadData";
export const GET_ALL_LEADS = "getAllLeadData";
export const GET_LISTING_PROJECTS = "getProjectListingData";
export const GET_LISTING_LEAD_SALES = "getLeadSalesListingData";

// mutation types
export const SET_USERS = "setUsers";
export const SET_LOADING = "setLoading";
export const SET_ACTIVE_FILTER = "setActiveFilter";

const state = {
	loading: false,
	users: [],
	customer_count: {},
	active_customer_filter: {
		text: "This Month",
		value: "this_month",
	},
	/* Leaves Overview States */
	leave_duration_list: [
		{
			text: "This Week",
			value: "this_week",
		},
		{
			text: "This Month",
			value: "this_month",
		},
		{
			text: "This Year",
			value: "this_year",
		},
	],
	active_leave_filter: {
		text: "This Month",
		value: "this_month",
	},
	leave_count: {
		allLeaves: 0,
		pending_leaves: 0,
		accepted_leave: 0,
		rejected_leave: 0,
	},
	/* Claims Overview States */
	claim_duration_list: [
		{
			text: "This Week",
			value: "this_week",
		},
		{
			text: "This Month",
			value: "this_month",
		},
		{
			text: "This Year",
			value: "this_year",
		},
	],
	active_claim_filter: {
		text: "This Month",
		value: "this_month",
	},
	claim_count: {
		allClaims: 0,
		pending_claim: 0,
		accepted_claim: 0,
		rejected_claim: 0,
	},
	/* Lead sales count States */
	lead_sales_list: [],
	active_lead_sales_duration: {
		text: "This Week",
		value: "this_week",
	},
	/* Project Overview States */
	projects: {},
	active_project_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_project_type: {
		text: "All Type",
		value: "all",
	},
	active_project_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
	/* Meeting Overview States */
	meetings: {},
	active_meeting_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_meeting_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
	/* Sales Overview States */
	sales: {},
	active_sales_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_sales_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
	/* Closed Lead Source Overview States */
	closed_leads: {},
	active_closed_lead_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_closed_lead_type: {
		text: "All type",
		value: "all",
	},
	active_closed_lead_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
	/* All Lead Source Overview States */
	all_leads: {},
	active_all_lead_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_all_lead_type: {
		text: "All type",
		value: "all",
	},
	active_all_lead_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
	/* All Project  Overview States */
	listing_projects: [],
	active_listing_project_status: "recurring",
	active_listing_project_duration: {
		text: "This Month",
		value: "this_month",
	},
	active_listing_project_user: {
		id: 0,
		display_name: "All Users",
		profile_img: null,
		total_project: 0,
	},
};

const getters = {
	dLoading(state) {
		return state.loading;
	},
	dUsers(state) {
		return state.users;
	},
	dCustomers(state) {
		return state.customer_count;
	},
	/* Leaves Getters */
	dLeaveDurationList(state) {
		return state.leave_duration_list;
	},
	dActiveLeaveFilter(state) {
		return state.active_leave_filter;
	},
	dLeaves(state) {
		return state.leave_count;
	},
	/* Claims Getters */
	dClaimDurationList(state) {
		return state.claim_duration_list;
	},
	dActiveClaimFilter(state) {
		return state.active_claim_filter;
	},
	dClaims(state) {
		return state.claim_count;
	},
	/* Projects Getters */
	dActiveProjectDuration(state) {
		return state.active_project_duration;
	},
	dActiveProjectType(state) {
		return state.active_project_type;
	},
	dActiveProjectUser(state) {
		return state.active_project_user;
	},
	dProjects(state) {
		return state.projects;
	},
	/* Meeting Getters */
	dActiveMeetingDuration(state) {
		return state.active_meeting_duration;
	},
	dActiveMeetingUser(state) {
		return state.active_meeting_user;
	},
	dMeetings(state) {
		return state.meetings;
	},
	/* Sales Getters */
	dActiveSalesDuration(state) {
		return state.active_sales_duration;
	},
	dActiveSalesUser(state) {
		return state.active_sales_user;
	},
	dSales(state) {
		return state.sales;
	},
	/* Closed Lead Getters */
	dActiveClosedLeadDuration(state) {
		return state.active_closed_lead_duration;
	},
	dActiveClosedLeadType(state) {
		return state.active_closed_lead_type;
	},

	dActiveClosedLeadUser(state) {
		return state.active_closed_lead_user;
	},
	dClosedLeads(state) {
		return state.closed_leads;
	},
	/* All Lead Getters */
	dActiveAllLeadDuration(state) {
		return state.active_all_lead_duration;
	},
	dActiveAllLeadType(state) {
		return state.active_all_lead_type;
	},
	dActiveAllLeadUser(state) {
		return state.active_all_lead_user;
	},
	dAllLeads(state) {
		return state.all_leads;
	},
	/* Listing Project Getters */
	dActiveListingProjectStatus(state) {
		return state.active_listing_project_status;
	},
	dActiveListingProjectDuration(state) {
		return state.active_listing_project_duration;
	},
	dActiveListingProjectUser(state) {
		return state.active_listing_project_user;
	},
	dListingProjects(state) {
		return state.listing_projects;
	},
	/* Lead Sales Listing  Getters */
	dActiveLeadSalesDuration(state) {
		return state.active_lead_sales_duration;
	},
	dListingLeadSales(state) {
		return state.lead_sales_list;
	},
};

const actions = {
	[GET_CLAIMS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("claim-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_CLAIMS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_LEAVES](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("leaves-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_LEAVES, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_PROJECTS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("projects-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_PROJECTS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_MEETINGS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("meetings-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_MEETINGS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_SALES](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("seo-softwares-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_SALES, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_CLOSED_LEADS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("closed-leads-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_CLOSED_LEADS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_ALL_LEADS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("leads-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_ALL_LEADS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_LISTING_PROJECTS](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("seoprojects-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data.data");
					context.commit(GET_LISTING_PROJECTS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[GET_LISTING_LEAD_SALES](context, params) {
		return new Promise((resolve, reject) => {
			context.commit(SET_LOADING, true);
			ApiService.setHeader();
			ApiService.query("leads-sales-counts", params)
				.then((output) => {
					const data = ObjectPath.get(output, "data");
					context.commit(GET_LISTING_LEAD_SALES, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
};

const mutations = {
	[SET_LOADING](state, payload) {
		state.loading = payload;
	},
	[SET_ACTIVE_FILTER](state, data) {
		state[data.key] = data.value;
	},
	[GET_CLAIMS](state, data) {
		state.claim_count = data;
	},
	[GET_LEAVES](state, data) {
		state.leave_count = data;
	},
	[GET_PROJECTS](state, data) {
		state.projects = data;
	},
	[GET_MEETINGS](state, data) {
		state.meetings = data;
	},
	[GET_SALES](state, data) {
		state.sales = data;
	},
	[GET_CLOSED_LEADS](state, data) {
		state.closed_leads = data;
	},
	[GET_ALL_LEADS](state, data) {
		state.all_leads = data;
	},
	[GET_LISTING_PROJECTS](state, data) {
		state.listing_projects = data;
	},
	[GET_LISTING_LEAD_SALES](state, data) {
		state.lead_sales_list = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
