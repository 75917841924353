import ApiService from "@/core/services/api.service";
import { toSafeInteger, isArray } from "lodash";

// mutation types
export const ERROR = "error";
export const MESSAGE = "message";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_REMARK = "setRemark";
export const SET_NOTIFICATION_DRAWER = "setNotificationDrawer";
export const SET_NOTIFICATION_COUNT = "setNotificationCount";

const state = {
	errors: [],
	messages: [],
	remark: null,
	notification_count: 0,
	notification_drawer: false,
};

const getters = {
	errors(state) {
		return state.errors;
	},
	remark(state) {
		return state.remark;
	},
	messages(state) {
		return state.messages;
	},
	notificationDrawer: (state) => {
		return state.notification_drawer;
	},
	notificationCount: (state) => {
		return state.notification_count;
	},
};

const actions = {
	[ERROR](context, param) {
		context.commit(SET_ERROR, param);
	},
	[MESSAGE](context, param) {
		context.commit(SET_MESSAGE, param);
	},
	[SET_NOTIFICATION_COUNT](context) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(`notification-count`)
				.then(({ data }) => {
					context.commit(SET_NOTIFICATION_COUNT, data.unread);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_MESSAGE](state, message) {
		if (message && isArray(message)) {
			state.messages = message.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.messages = [];
		}
	},
	[SET_REMARK](state, data) {
		state.remark = data;
	},
	[SET_NOTIFICATION_DRAWER](state, value) {
		state.notification_drawer = value;
	},
	[SET_NOTIFICATION_COUNT](state, value) {
		state.notification_count = value;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
