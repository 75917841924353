import Vue from "vue";
import Router from "vue-router";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";

function AllowAnyOforGetRoleNType(allowed = [], isRole = false) {
	const CURRENT_USER = getCurrentUser();

	if (allowed.length === 0) {
		return false;
	}

	const roles = {
		master: CURRENT_USER.is_master,
		admin: [1, 6, 12].includes(CURRENT_USER.role),
		manager: [4, 7, 13].includes(CURRENT_USER.role),
		super_admin: CURRENT_USER.users_type === null,
		team_lead: CURRENT_USER.role === 8,
		ateam_lead: CURRENT_USER.role === 9,
		on_page: CURRENT_USER.role === 10,
		off_page: CURRENT_USER.role === 11,
	};

	if (isRole == "role") {
		objectPath.get(CURRENT_USER, "role");
	} else {
		return allowed.some((role) => roles[role]);
	}
}

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard"),
					meta: {
						menuActive: "dashboard",
					},
				},
				{
					path: "dashboard-ui",
					name: "dashboard-ui",
					component: () => import("@/view/pages/dashboard-ui/DashboardUI.vue"),
					meta: {
						menuActive: "dashboard-ui",
					},
				},
				{
					path: "contacts",
					name: "contacts",
					component: () => import("@/view/module/contacts/Contacts"),
					children: [
						{
							path: "customer",
							name: "customer",
							component: () => import("@/view/module/contacts/customer/Customer"),
							meta: {
								menuActive: "customer",
							},
						},
						{
							path: "customer/create",
							name: "customer-create",
							component: () => import("@/view/module/contacts/customer/Create-Customer3"),
							meta: {
								menuActive: "customer",
							},
						},
						{
							path: "customer/update/:id",
							name: "update-customer",
							component: () => import("@/view/module/contacts/customer/Create-Customer3"),
							meta: {
								menuActive: "customer",
							},
						},
						/* {
							path: "customer/:uuid",
							name: "customer-detail",
							component: () => import("@/view/module/contacts/customer/Detail-Customer"),
						}, */
						{
							path: "customer/customer-detail/:id",
							name: "customer-detail2",
							component: () => import("@/view/module/contacts/customer/Detail-Customer3"),
							meta: {
								menuActive: "customer",
							},
						},
						{
							path: "leads",
							name: "leads",
							component: () => import("@/view/pages/leads/Leads"),
							meta: {
								menuActive: "leads",
							},
						},
						{
							path: "leads/leads-detail/:id",
							name: "leads-detail",
							component: () => import("@/view/pages/leads/Leads-Detail"),
							meta: {
								menuActive: "leads",
							},
						},
						{
							path: "leads/create-lead",
							name: "create-lead",
							component: () => import("@/view/pages/leads/Create-Lead"),
							meta: {
								menuActive: "leads",
							},
						},
						{
							path: "leads/update-lead/:id",
							name: "update-lead",
							component: () => import("@/view/pages/leads/Create-Lead"),
							meta: {
								menuActive: "leads",
							},
						},
					],
				},
				{
					path: "project",
					name: "project",
					component: () => import("@/view/module/project/Project"),
					meta: {
						menuActive: "project",
					},
				},
				{
					path: "reminder-listing",
					name: "all-reminder",
					component: () => import("@/view/pages/leads/create/ReminderListing"),
				},
				/* {
					path: "project-create",
					name: "create-project",
					component: () => import("@/view/module/project/CreateProject"),
				}, */
				{
					path: "contract",
					name: "contract",
					component: () => import("@/view/module/contract/Contract"),
					meta: {
						menuActive: "contract",
					},
				},
				{
					path: "contract/detail-contract/:id",
					name: "contract-detail",
					component: () => import("@/view/module/contract/Detail-Contract"),
					meta: {
						menuActive: "contract",
					},
				},
				{
					path: "invoice",
					name: "invoice",
					meta: {
						menuActive: "invoice",
					},
					component: () => import("@/view/module/components/RecurringInvoiceTab"),
				},

				{
					path: "project/create-project",
					name: "create-project",
					component: () => import("@/view/module/project/CreateProject2"),
					meta: {
						menuActive: "project",
					},
				},
				{
					path: "project/create-renewed-project",
					name: "create-renewed-project",
					component: () => import("@/view/module/project/CreateProject2"),
					meta: {
						menuActive: "project",
					},
				},
				{
					path: "project/update-project/:id",
					name: "update-project",
					component: () => import("@/view/module/project/CreateProject2"),
					meta: {
						menuActive: "project",
					},
				},

				{
					path: "project/detail-project/:id",
					name: "project-detail",
					component: () => import("@/view/module/project/Detail-Project"),
					meta: {
						menuActive: "project",
					},
				},
				{
					path: "project/gantt-chart",
					name: "gantt-chart",
					component: () => import("@/view/module/gantt-chart/GanttChart"),
				},
				{
					path: "meeting",
					name: "meeting",
					component: () => import("@/view/module/meeting/Meeting"),
					meta: {
						menuActive: "meeting",
					},
				},
				{
					path: "meeting/detail-meeting/:id",
					name: "meeting-detail",
					component: () => import("@/view/module/meeting/Detail-Meeting"),
					meta: {
						menuActive: "meeting",
					},
				},
				{
					path: "leave",
					name: "leave",
					component: () => import("@/view/module/leave/Leave"),
					meta: {
						menuActive: "leave",
					},
				},
				{
					path: "leave/detail-leave/:id",
					name: "leave-detail",
					component: () => import("@/view/module/leave/Detail-Leave"),
					meta: {
						menuActive: "leave",
					},
				},
				{
					path: "holiday",
					name: "holiday",
					component: () => import("@/view/module/holiday/Holiday"),
					meta: {
						menuActive: "holiday",
					},
				},
				{
					path: "task",
					name: "task",
					component: () => import("@/view/module/task/Task"),
					meta: {
						menuActive: "task",
					},
				},
				{
					path: "task/detail-task/:id",
					name: "task-detail",
					component: () => import("@/view/module/task/Detail-Task"),
					meta: {
						menuActive: "task",
					},
				},
				{
					path: "my-day",
					name: "my-day",
					component: () => import("@/view/module/personal-task/MyDayTask"),
					meta: {
						menuActive: "my-day",
					},
				},
				{
					path: "next-days",
					name: "next-days",
					component: () => import("@/view/module/personal-task/NextDays"),
					meta: {
						menuActive: "next-days",
					},
				},
				{
					path: "all-task",
					name: "all-task",
					component: () => import("@/view/module/personal-task/AllMyTask"),
					meta: {
						menuActive: "all-task",
					},
				},
				{
					path: "my-calendar",
					name: "my-calendar",
					component: () => import("@/view/module/personal-task/MyTaskCalendar"),
					meta: {
						menuActive: "my-calendar",
					},
				},
				// {
				// 	path: "people",
				// 	name: "people",
				// 	component: () => import("@/view/module/personal-task/TaskPeople"),
				// },
				{
					path: "invoice/detail-invoice/:id",
					name: "invoice-detail",
					component: () => import("@/view/module/components/Detail-Invoice"),
					meta: {
						menuActive: "invoice",
					},
				},

				{
					path: "claims",
					name: "claims",
					component: () => import("@/view/module/claims/Claims"),
					meta: {
						menuActive: "claims",
					},
				},
				{
					path: "software",
					name: "software",
					component: () => import("@/view/module/software/SoftwareListing"),
					meta: {
						menuActive: "software",
					},
				},
				{
					path: "software/detail-software/:id",
					name: "software-detail",
					component: () => import("@/view/module/software/Detail-Software"),
					meta: {
						menuActive: "software",
					},
				},
				{
					path: "domain",
					name: "domain",
					component: () => import("@/view/module/domain/Domain"),
					meta: {
						menuActive: "domain",
					},
				},
				{
					path: "domain/domain-detail/:id",
					name: "domain-detail",
					component: () => import("@/view/module/domain/DomainDetail"),
					meta: {
						menuActive: "domain",
					},
				},
				{
					path: "seo/seo-detail/:id",
					name: "seo-detail",
					component: () => import("@/view/module/seo/Seo-Detail-new"),
					meta: {
						menuActive: "seo",
					},
				},
				{
					path: "seo/customer-seo-detail/:id",
					name: "customerSeoDetail",
					component: () => import("@/view/module/seo/CustomerSeoDetail"),
					meta: {
						menuActive: "seo",
					},
				},
				{
					path: "ssl",
					name: "ssl",
					component: () => import("@/view/module/ssl/SSL"),
					meta: {
						menuActive: "ssl",
					},
				},
				{
					path: "ssl/ssl-detail/:id",
					name: "ssl-detail",
					component: () => import("@/view/module/ssl/SSLDetail"),
				},
				{
					path: "claims/create-claims",
					name: "create-claims",
					component: () => import("@/view/module/claims/Create-Claims"),
					meta: {
						menuActive: "claims",
					},
				},
				{
					path: "claims/update-claim/:id",
					name: "update-claim",
					component: () => import("@/view/module/claims/Create-Claims"),
					meta: {
						menuActive: "claims",
					},
				},
				{
					path: "claims/detail-claims/:id",
					name: "claims-details",
					component: () => import("@/view/module/claims/Claims-detail"),
					meta: {
						menuActive: "claims",
					},
				},
				{
					path: "recurring",
					name: "recurring",
					component: () => import("@/view/module/recurring/Recurring"),
				},
				{
					path: "recurring/create-recurring",
					name: "create-recurring",
					component: () => import("@/view/module/recurring/CreateRecurring"),
				},
				{
					path: "claims/update-recurring/:id",
					name: "update-recurring",
					component: () => import("@/view/module/recurring/CreateRecurring"),
				},

				{
					path: "recurring/detail-recurring/:id",
					name: "recurring-details",
					component: () => import("@/view/module/recurring/DetailRecurring"),
				},
				{
					path: "project-renewed",
					name: "project-renewed",
					component: () => import("@/view/module/projectRrenewed"),
				},
				{
					path: "project-to-be-renewed/:id",
					name: "renew-detail",
					component: () => import("@/view/module/recurring/Detail-Renew"),
				},
				{
					path: "cancelled-projects",
					name: "cancelled-projects",
					component: () => import("@/view/module/CancelledList.vue"),
				},
				{
					path: "activity",
					name: "taskactivity",
					component: () => import("@/view/module/Taskactivity.vue"),
					meta: {
						menuActive: "activity",
					},
				},
				{
					path: "seo-dashboard",
					name: "seo-dashboard",
					component: () => import("@/view/module/seo/SEODashboard"),
					meta: {
						menuActive: "seo-dashboard",
					},
				},
				/* {
					path: "seo/detail",
					name: "seo-detail",
					component: () => import("@/view/module/seo/SeoProjectDetail"),
				}, */
				{
					path: "cancelled-projects/:id",
					name: "cancel-detail",
					component: () => import("@/view/module/recurring/Detail-Cancel"),
				},
				{
					path: "support",
					name: "support-projects",
					component: () => import("@/view/module/support/Support.vue"),
				},
				{
					path: "invoice-pending-notifications",
					name: "invoice-pending-notifications",
					component: () => import("@/view/module/InvoicePendingNotification.vue"),
				},
				{
					path: "payment-pending-notifications",
					name: "payment-pending-notifications",
					component: () => import("@/view/module/PaymentNotification.vue"),
				},
				{
					path: "other-notifications",
					name: "other-notifications",
					component: () => import("@/view/module/NotificationOthers.vue"),
				},
				{
					path: "notifications",
					name: "notifications",
					component: () => import("@/view/module/Notifications.vue"),
				},
				{
					path: "seo",
					name: "seo",
					component: () => import("@/view/module/seo/SeoProject"),
					meta: {
						menuActive: "seo",
					},
				},
				{
					path: "customer-seo",
					name: "customerSeo",
					component: () => import("@/view/module/seo/CustomerSeoProjects"),
					meta: {
						menuActive: "seo",
					},
				},
				{
					path: "ranking",
					name: "ranking",
					meta: {
						menuActive: "ranking",
					},
					component: () => import("@/view/module/seo/Ranking"),
				},
				{
					path: "excel",
					name: "excel",
					component: () => import("@/view/module/excel-file/ExcelFileListing"),
				},
				{
					path: "create-excel",
					name: "create-excel",
					component: () => import("@/view/module/excel-file/Create-ExcelFile"),
				},
				{
					path: "activity",
					name: "activity",
					component: () => import("@/view/module/activity/Activiity"),
				},
				{
					path: "report",
					name: "report",
					component: () => import("@/view/module/reports/Report"),
				},
				/* {
					path: "profile",
					name: "profile",
					component: () => import("@/view/pages/UserProfile"),
				}, */
				{
					path: "user/profile/:id",
					name: "user-profile",
					component: () => import("@/view/pages/UserProfile"),
				},
				{
					path: "role/create",
					name: "role.create",
					component: () => import("@/view/module/setting/RolePermissionPages/CreateRole"),
					/* meta: {
						requiresAuth: true,
						title: "Create Role",
					}, */
				},
				{
					path: "role/update/:id",
					name: "role.update",
					component: () => import("@/view/module/setting/RolePermissionPages/CreateRole"),
					/* 	meta: {
						requiresAuth: true,
						title: "Update Role",
					}, */
				},
				{
					path: "users",
					name: "users",
					component: () => import("@/view/module/users/Users"),
					beforeEnter: (to, from, next) => {
						/* [ ATL, ON Page, OFF Page ] Roles - cannot access this route */
						if (
							AllowAnyOforGetRoleNType(["ateam_lead", "on_page", "off_page"]) ||
							Boolean(+AllowAnyOforGetRoleNType([], "role"))
						) {
							next({ path: "/" });
						} else {
							next();
						}
					},
					meta: {
						menuActive: "users",
					},
				},
				{
					path: "user/user-detail/:id",
					name: "user-detail",
					component: () => import("@/view/module/users/Detail-User"),
					meta: {
						menuActive: "users",
					},
				},
				{
					path: "setting/:tab?",
					name: "setting",
					component: () => import("@/view/module/setting/Setting"),
				},
				{
					path: "setting/details/:id",
					name: "server-details",
					component: () => import("@/view/module/setting/ServerDetails"),
				},
				{
					path: "calendar",
					name: "calendar",
					component: () => import("@/view/module/calendar/Calendar"),
				},
				{
					path: "resource-calender",
					name: "resource-calender",
					component: () => import("@/view/module/calendar/ResourceCalender2"),
				},
				{
					path: "setting/email-template-update/:id",
					name: "email-template-update",
					component: () => import("@/view/module/setting/email/EmailTemplateUpdate"),
				},
				{
					path: "member",
					name: "member",
					component: () => import("@/view/module/seoReport/Member"),
				},
				{
					path: "viewDashboard",
					name: "viewDashboard",
					component: () => import("@/view/module/viewDashboard/Dashboard"),
				},
			],
		},
		{
			path: "/custom-error",
			name: "error",
			component: () => import("@/view/pages/error/Error"),
			children: [
				{
					path: "error-1",
					name: "error-1",
					component: () => import("@/view/pages/error/Error-1"),
				},
			],
		},
		{
			name: "authenticate",
			path: "/",
			component: () => import("@/view/pages/auth/Authenticate"),
			children: [
				{
					path: "login",
					name: "login",
					component: () => import("@/view/pages/auth/Login"),
				},
				{
					path: "login-accounts",
					name: "login-accounts",
					component: () => import("@/view/pages/auth/User-Login"),
				},
				{
					path: "login-sales",
					name: "login-sales",
					component: () => import("@/view/pages/auth/User-Login"),
				},
				{
					path: "login-seo",
					name: "login-seo",
					component: () => import("@/view/pages/auth/User-Login"),
				},
				{
					path: "login-customers",
					name: "login-customers",
					component: () => import("@/view/pages/auth/User-Login"),
				},
				/* {
					path: "accounts/login",
					name: "login",
					component: () => import("@/view/pages/auth/Login"),
				}, */
			],
		},
		{
			path: "*",
			redirect: "/404",
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1"),
		},
	],
});
