import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";

// action types
export const SET_LOCAL_DB = "setLocalDB";
export const SET_RELATED_DB = "setRelatedDB";
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const SET_SIDE_MENU = "setSideMenu";

export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";
export const SET_ERROR = "setError";

// mutation types

export default {
	state: {
		config: config,
		initial: config,
		localDB: {},
		relatedDB: {},
		side_menu: "max",
	},
	getters: {
		/**
		 * Get config from layout config
		 * @param state
		 * @returns {function(path, defaultValue): *}
		 */
		layoutConfig: (state) => (path, defaultValue) => {
			return objectPath.get(state.config, path, defaultValue);
		},
		/**
		 * Get data from local db
		 * @param state
		 * @returns {function(path, defaultValue): *}
		 */
		localDB: (state) => (path, defaultValue) => {
			return objectPath.get(state.localDB, path, defaultValue);
		},
		relatedDB: (state) => (path, defaultValue) => {
			return objectPath.get(state.relatedDB, path, defaultValue);
		},
		getUsers: (state) => {
			return state.localDB?.allUsers;
		},
		sideMenu(state) {
			return state.side_menu;
		},
	},
	actions: {
		/**
		 * Set and replace the local db
		 * @param state
		 * @param payload
		 */
		[SET_LOCAL_DB](state) {
			return new Promise((resolve) => {
				ApiService.get("get-config")
					.then(({ data }) => {
						state.commit(SET_LOCAL_DB, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},
		/**
		 * Set and replace the related db
		 * @param state
		 * @param payload
		 */
		[SET_RELATED_DB](state) {
			return new Promise((resolve) => {
				ApiService.get("related-field")
					.then(({ data }) => {
						state.commit(SET_RELATED_DB, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},
		/**
		 * Set and replace the whole config
		 * @param state
		 * @param payload
		 */
		[SET_LAYOUT_CONFIG](state, payload) {
			state.commit(SET_LAYOUT_CONFIG, payload);
		},

		/**
		 * Reset the config at the initial state
		 * @param state
		 */
		[RESET_LAYOUT_CONFIG](state) {
			state.commit(RESET_LAYOUT_CONFIG);
		},

		/**
		 * Reset the config using saved config in the cache by the layout builder
		 * @param state
		 */
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.commit(OVERRIDE_LAYOUT_CONFIG);
		},

		/**
		 * Override config by page level
		 * @param state
		 * @param payload
		 */
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
		},
	},
	mutations: {
		[SET_LOCAL_DB](state, payload) {
			state.localDB = payload;
		},
		[SET_SIDE_MENU](state, payload) {
			state.side_menu = payload || "max";
		},
		[SET_RELATED_DB](state, payload) {
			state.relatedDB = payload;
		},
		[SET_LAYOUT_CONFIG](state, payload) {
			state.config = payload;
		},
		[RESET_LAYOUT_CONFIG](state) {
			state.config = Object.assign({}, state.initial);
		},
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.config = state.initial = Object.assign(
				{},
				state.initial,
				JSON.parse(localStorage.getItem("config"))
			);
		},
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.config = merge(state.config, payload);
		},
	},
};
