import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import objectPath from "object-path";

// action types
export const LOGIN = "login";
export const TEST_LOGIN = "testLogin";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
/* export const SET_ROLE = "setRole"; */
export const SET_ERROR = "setError";

const state = {
	errors: [],
	user: JwtService.getCurrentUser(),
	currentUserPermissions: JwtService.getPermissions(),
	isAuthenticated: !!JwtService.getToken(),
	user_type: objectPath.get(JwtService.getCurrentUser(), "users_type"),
	is_master: objectPath.get(JwtService.getCurrentUser(), "is_master"),
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	currentUserPermissions(state) {
		return state.currentUserPermissions;
	},
	isAuthenticated() {
		return state.isAuthenticated;
	},
	userType(state) {
		return state.user_type;
	},
	isMaster(state) {
		return state.is_master;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			// http://127.0.0.1:8000/api/v1/login
			ApiService.post("auth/login", credentials)
				.then((data) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
					if (error && error.response) {
						context.commit(SET_ERROR, commitErrors(error.response));
						reject(error.response);
					} else {
						context.commit(SET_ERROR, commitErrors(error));
						reject(error);
					}
				});
		});
	},
	[TEST_LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("login", credentials)
				.then((response) => {
					context.commit(SET_AUTH, response.data.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
					if (error && error.response) {
						context.commit(SET_ERROR, commitErrors(error.response));
						reject(error.response);
					} else {
						context.commit(SET_ERROR, commitErrors(error));
						reject(error);
					}
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
};
const commitErrors = (response) => {
	let requestErrors = [];
	let data = response.data;

	if (data && data.message) {
		requestErrors.push(data.message);
	} else if (response && response.status === 422) {
		if (data.errors) {
			for (let error in data.errors) {
				if (data.errors[error]) {
					for (let i = data.errors[error].length - 1; i >= 0; i--) {
						requestErrors.push(data.errors[error][i]);
					}
				}
			}
		} else {
			for (let error in data) {
				if (data[error]) {
					for (let i = data[error].length - 1; i >= 0; i--) {
						requestErrors.push(data[error][i]);
					}
				}
			}
		}
	} else if (response && response.status === 207) {
		requestErrors.push(data.error);
	} else if (response && response.status === 401) {
		requestErrors.push(data.error);
	} else if (response && response.status === 500) {
		requestErrors.push(data.message);
	} else if (response && response.status === 400) {
		requestErrors.push(data.message);
	} else if (response && response.status === 404) {
		requestErrors.push("Sorry ! The page you requested was not found.");
	} else if (response && response.status === 405) {
		requestErrors.push(data.message);
	} else {
		requestErrors.push(response.toString());
	}
	return requestErrors;
};
const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, data) {
		state.isAuthenticated = true;
		state.currentUserPermissions = data.permissions;
		state.user = data.user;
		state.user_type = objectPath.get(data, "user.users_type");
		state.is_master = objectPath.get(data, "user.is_master");
		state.errors = {};
		JwtService.saveToken(data);
	},
	/* [SET_ROLE](state, data) {
		state.currentUserPermissions = data;
	}, */
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		JwtService.destroyToken();
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
