/* eslint-disable */
import {
	toSafeInteger,
	isObject,
	isEmpty,
	capitalize,
	toLower,
	identity,
	includes,
	pickBy,
	cloneDeep,
} from "lodash";
import { mapGetters } from "vuex";
import { formatMoney } from "accounting-js";
import { getCurrentUser } from "@/core/services/jwt.service";
import { format } from "date-fns";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MomentJS from "moment-timezone";
import objectPath from "object-path";

const validateEmail = (email) => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(String(email).toLowerCase());
};

const validateUrl = (url) => {
	const re =
		/^((https?|http)?:\/\/?(?:www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
	/* 
		/^(https?|ftp)?:\/\/?(?:www\.)?[a-zA-Z0-9-]*(?:\.[a-zA-Z]{2,})+(?::[0-9]+)?(?:\/[^\s?]*)?(?:\?[^\s]*)?$|^www\.[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?(?::[0-9]+)?(?:\/[^\s?]*)?(?:\?[^\s]*)?$|^[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?(?::[0-9]+)?(?:\/[^\s?]*)?(?:\?[^\s]*)?$/g;
		/^(https?|ftp)?:\/\/?(?:www\.)?[a-zA-Z0-9-]*(?:\.[a-zA-Z]{2,})+(?::[0-9]+)?(?:\/(?:[a-zA-Z0-9-]+\/?)*)?(?:\?[^\s]*)?$|^www\.[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?(?::[0-9]+)?(?:\/(?:[a-zA-Z0-9-]+\/?)*)?(?:\?[^\s]*)?$|^[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?(?::[0-9]+)?(?:\/(?:[a-zA-Z0-9-]+\/?)*)?$|^\/(?:[a-zA-Z0-9-]+\/?)?(?:\?[^\s]*)?$/ 
	*/

	return re.test(String(url).toLowerCase());
};

class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					today: null,
					uniqFieldId: null,
					listTitle: [
						{
							value: "mr",
							text: "Mr.",
						},
						{
							value: "ms",
							text: "Ms.",
						},
						{
							value: "mrs",
							text: "Mrs.",
						},
						{
							value: "miss",
							text: "Miss.",
						},
						{
							value: "dr",
							text: "Dr.",
						},
					],
					listAccounting: [
						{
							value: "none",
							text: "NONE",
						},
						{
							value: "fifo",
							text: "FIFO (First In First Out)",
						},
						{
							value: "fefo",
							text: "FEFO (First Expire First Out)",
						},
						{
							value: "lifo",
							text: "LIFO (Last In First Out)",
						},
					],
					listRadioCondition: [
						{
							label: "Yes",
							value: true,
						},
						{
							label: "No",
							value: false,
						},
					],
					durationList: [
						{
							text: "Day",
							value: "day",
						},
						{
							text: "Week",
							value: "week",
						},
					],
					vrules: {
						required(val, field, isInteger) {
							if (isObject(val) && isEmpty(val)) {
								return `${field} is required`;
							}
							if (isInteger && parseInt(val) <= 0) {
								return `${field} is required.`;
							}
							return !!val || `${field} is required`;
						},
						validateQty(val, maxQty, field) {
							let newQty = toSafeInteger(val);
							let newAvailable = toSafeInteger(maxQty);
							if (newQty > newAvailable) {
								return `${field} is must be less than or equal to ${newAvailable}.`;
							}
							return true;
						},
						validEmail(val, field) {
							if (val) {
								if (validateEmail(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validFloat(val, field) {
							if (val) {
								if (/^\d*\.?\d*$/.test(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validNumeric(val, field) {
							if (val) {
								if (/^\d+$/.test(val)) {
									return true;
								}
								return `${field} must be valid (only digits) & greater than 0`;
							}
							return true;
						},
						maxLength(val, field, maxLen) {
							if (val) {
								val = val ? val.toString() : '';
								if (val.length <= maxLen) {
									return true;
								}
								return `${field} length must be less than or equal to ${maxLen}`;
							}
							return true;
						},
						minLength(val, field, minLen) {
							if (val) {
								val = val ? val.toString() : '';
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						lessThan(val, field, len) {
							if (val) {
								val = parseInt(val);
								if (val <= len) {
									return true;
								}
								return `${field} must be less than or equal to ${len}`;
							}
							return true;
						},
						greaterThan(val, field, minLen) {
							if (val) {
								val = val ? val.toString() : '';
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						deleteValidation(val, field, barcode) {
							if (val) {
								val = val ? val.toString() : '';
								barcode = barcode ? barcode.toString() : '';
								if (val === barcode) {
									return true;
								}
								return `${field} must be equal to ${barcode}`;
							}
							return true;
						},
						confirmPassword(val, field, old) {
							if (val) {
								val = val ? val.toString() : '';
								old = old ? old.toString() : '';
								if (val === old) {
									return true;
								}
								return `${field} you entered don't match to new password`;
							}
							return true;
						},
						validUrl(val, field, noRequired) {
							if (val) {
								if (validateUrl(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							if (noRequired) {
								return true;
							}
							return `${field} is required`;
						},
						charCount(val, field, maxmin = []) {
							if (val) {
								const _val = val && val.toString()?.length;
								if (maxmin.length == 2 && _val >= maxmin[0] && _val <= maxmin[1]) {
									return true;
								} else if (maxmin.length == 1 && _val >= maxmin[0]) {
									return true;
								}
								return `${field} should be ${maxmin[0]} to ${maxmin[1]} characters.`;
							}
							return true;
						},
					},
				};
			},
			methods: {
				/* AllowOnlyTo(allowed = []) {
					const CURRENT_USER = getCurrentUser();
				},
				AllowExcept(allowed = []) {
					const CURRENT_USER = getCurrentUser();
					if (allowed.length) {
						if ((allowed.includes("master") && CURRENT_USER.is_master)) {
							
						}
					} else {
						return false
					}
				}, */
				/* AllowAnyOforGetRoleNType(allowed = []) {
					const CURRENT_USER = getCurrentUser();

					if (allowed.length) {
						if (
							(allowed.includes("master") && CURRENT_USER.is_master) ||
							(allowed.includes("admin") &&
								(CURRENT_USER.role == 1 || CURRENT_USER.role == 6 || CURRENT_USER.role == 12)) ||
							(allowed.includes("manager") &&
								(CURRENT_USER.role == 4 || CURRENT_USER.role == 7 || CURRENT_USER.role == 13)) ||
							(allowed.includes("super_admin") && CURRENT_USER.users_type == null) ||
							(allowed.includes("team_lead") && CURRENT_USER.role == 8) ||
							(allowed.includes("ateam_lead") && CURRENT_USER.role == 9) ||
							(allowed.includes("on_page") && CURRENT_USER.role == 10) ||
							(allowed.includes("off_page") && CURRENT_USER.role == 11)
						) {
							return true;
						} else {
							return false;
						}
					} else {
						return false;
					}
				} */

				AllowAnyOforGetRoleNType(allowed = []) {
					const CURRENT_USER = getCurrentUser();

					if (allowed.length === 0) {
						return {
							role: objectPath.get(CURRENT_USER, "role"),
							users_type: objectPath.get(CURRENT_USER, "users_type"),
							id: objectPath.get(CURRENT_USER, "id"),
						};
					}

					const roles = {
						master: CURRENT_USER.is_master,
						admin: [1, 6, 12].includes(CURRENT_USER.role),
						manager: [4, 7, 13].includes(CURRENT_USER.role),
						super_admin: CURRENT_USER.users_type === null,
						team_lead: CURRENT_USER.role === 8,
						ateam_lead: CURRENT_USER.role === 9,
						on_page: CURRENT_USER.role === 10,
						off_page: CURRENT_USER.role === 11,
					};

					return allowed.some((role) => roles[role]);
				},
				//Identify admin or manager
				checkAdminOrManager() {
					const user = getCurrentUser();
					/* 	
						returns role id for others
						Team Lead 		: 8
						A Team Lead 	: 9
						ON Page 		: 10
						OFF Page 		: 11
					*/
					/* const condition = user.is_master || user.role || objectPath.get(user, "role") || user.users_type; */
					let now;
					if (user.is_master) {
						now = "master";
						return "master";
					} else if (user.role == 1 || user.role == 6 || user.role == 12) {
						now = "admin";
						return "admin";
					} else if (user.role == 4 || user.role == 7 || user.role == 13) {
						now = "manager";
						return "manager";
					} else if (user.users_type == null) {
						now = "super_admin";
						return "super_admin";
					} else if (user.role == 8) {
						now = "team_lead";
						return "team_lead";
					} else if (user.role == 9) {
						now = "ateam_lead";
						return "ateam_lead";
					} else if (user.role == 10) {
						now = "on_page";
						return "on_page";
					} else if (user.role == 11) {
						now = "off_page";
						return "off_page";
					} else {
						return objectPath.get(user, "role");
					}
				},
				ANDEvery(conditions = []) {
					if (Array.isArray(conditions) && conditions.length) {
						return conditions.every((condition) => condition);
					} else {
						return false;
					}
				},
				ORAny(conditions = []) {
					if (Array.isArray(conditions) && conditions.length) {
						return conditions.some((condition) => condition);
					} else {
						return false;
					}
				},
				avoidSpaces(e) {
					if (e.code == "Space" || e.keyCode == 32 || e.which == 32) {
						e.preventDefault();
					}
				},
				clickAndCopy(string) {
					navigator.clipboard
						.writeText(string)
						.then(() => {
							this.$store.commit(SET_MESSAGE, [
								{
									model: true,
									message: `Success ! ${string} copied successfully.`,
								},
							]);
						})
						.catch(() => {
							this.$store.commit(SET_ERROR, [
								{
									model: true,
									message: `Error ! copying ${string}.`,
								},
							]);
						});
				},
				testEvent(event) {
					console.log("Test => ", event ?? "Yehh! It Works.");
				},
				/* hasKey(object, key) {
					return objectPath.has(object, key);
				},
				getValue(object, key) {
					return objectPath.get(object, key);
				}, */
				allowInput(event, allowOnly) {
					// $event, ['number', 'chars']
					let keyCode = event.keyCode || event.which || event.charCode;
					// 0-9 48-57
					// 97-122 a-z
					// 65-90 A-Z
					if (allowOnly.includes("number") && keyCode > 47 && keyCode < 58) {
						return false;
					} else if (
						allowOnly.includes("chars") &&
						((keyCode > 96 && keyCode < 123) || (keyCode > 64 && keyCode < 91))
					) {
						return false;
					} else {
						event.preventDefault();
					}
				},
				disablePaste(event) {
					/* this.$store.commit(SET_ERROR, [{ model: true, message: "Can't paste enter valid input !!" }]); */
					event.preventDefault();
				},
				pushToRoute(name, params, query) {
					this.$router.push({
						name,
						params: params,
						query: { ...query, t: new Date().getTime() },
					});
				},
				goBackStepper() {
					this.stepper--;
				},
				deepEmpty(param) {
					return cloneDeep(pickBy(param, identity));
				},
				formatDateTime(dateTime) {
					if (dateTime && dateTime != "null") {
						return format(new Date(dateTime), "dd/MM/yyyy hh:mm a");
					} else {
						return "";
					}
				},
				formatDateTimeRaw(dateTime) {
					return format(new Date(dateTime), "yyyy-MM-dd HH:mm");
				},
				formatDateTimeRawWithoutTime(dateTime) {
					return format(new Date(dateTime), "yyyy-MM-dd");
				},
				formatDate(date) {
					// return format(new Date(date), "dd/MM/yyyy");
					return MomentJS(date).format("DD/MM/yyyy");
				},
				formatMoney(money) {
					return formatMoney(money, {
						symbol: "$",
						decimal: ".",
						thousand: ",",
						precision: 2,
						format: "%s%v",
					});
				},
				currentUserData() {
					const user = getCurrentUser();
					return user;
				},
				/* formatDateRaw(date) {
					return format(new Date(date), "yyyy-MM-dd");
				}, */
				formatDateRaw(date) {
					return MomentJS(date).format("yyyy-MM-dd");
				},
				formatDate_Raw(date) {
					return MomentJS(date).format("yyyy-MM-DD");
				},
				validateForm(formRef) {
					this.$store.commit(SET_ERROR, []);
					const formErrors = [];
					const { inputs } = formRef;
					for (let i = 0; i < inputs.length; i++) {
						const { errorBucket } = inputs[i];
						for (let z = 0; z < errorBucket.length; z++) {
							const errorMessage = capitalize(toLower(errorBucket[z]));
							formErrors.push({
								model: true,
								message: errorMessage,
							});
						}
					}
					return formErrors;
				},
				goBack() {
					this.$router.go(-1);
				},
				parseRemarkString(text) {
					return text.replace("\n", "<br>");
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				getPermission(key) {
					try {
						/* 	if (this.currentUser && this.currentUser.is_owner == 1) {
							return true;
						} */
						if (!key || (this.currentUser && this.currentUser.is_master == 1)) {
							return true;
						}

						let permissions = this.currentUserPermissions;
						if (isEmpty(permissions) === false) {
							let keyArray = key.split(":");
							keyArray[1] = "full-access";
							let fullAccessKey = keyArray.join(":");
							if (includes(permissions, fullAccessKey)) {
								return true;
							}

							return includes(permissions, key);
						} else {
							console.log("destroy");
						}
					} catch (error) {
						this.logError({ error });
						//destroyToken();
						//this.goBack();
					}
				},
				stringUnique() {
					let result = "";
					let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
					let charactersLength = characters.length;
					for (let i = 0; i < 10; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}
					return result;
				},
				setContentHeight() {
					//console.log({'route': this.$route});
					let element = document.querySelector(".listing-height");
					if (element) {
						let bodyRect = document.body.getBoundingClientRect();
						if (bodyRect) {
							let elemRect = element.getBoundingClientRect();
							let offset = elemRect.top - bodyRect.top;
							element.style.cssText = `
								height:${window.innerHeight - (offset + 14)}px;
								overflow:auto;
							`;
						}
					}
				},
				calculate_next_day(date, days_to_plus = 1, format = "yyyymmdd", saperator = "-") {
					/* Using this function you can calculate next dates by default it will give one day
					plus your given date */
					let time_in_ms = new Date(date).getTime();
					let one_day_in_ms = 1000 * 60 * 60 * 24 * days_to_plus;
					let plus_one_day = +time_in_ms + +one_day_in_ms;
					let next_day = new Date(plus_one_day);
					let year = new Date(next_day).getFullYear();
					let month = new Date(next_day).getMonth() + 1;
					let day = new Date(next_day).getDate();

					let _day = day > 9 ? day : "0" + day;
					let _month = month > 9 ? month : "0" + month;

					let yyyymmdd = `${year}${saperator}${_month}${saperator}${_day}`;
					let ddmmyyyy = `${_day}${saperator}${_month}${saperator}${year}`;
					if (format == "yyyymmdd") {
						return yyyymmdd;
					} else if (format == "ddmmyyyy") {
						return ddmmyyyy;
					}
				},
				date_difference(start, end) {
					let start_Date = MomentJS(start);
					let end_Date = MomentJS(end);
					let totalDays = end_Date.diff(start_Date, "days");
					return totalDays;
				},
				mod_string(text, saperator = "_", joiner = " ") {
					let splitArr = text ? text.split(saperator) : [];
					let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
					return mod_arr.join(joiner);
				},
				MOMENTJS(...rest) {
					return MomentJS(...rest);
				},
			},
			mounted() {
				this.today = format(new Date(), "yyyy-MM-dd");
				this.uniqFieldId = this.stringUnique();
				const _this = this;
				setTimeout(() => {
					_this.setContentHeight();
				}, 500);
			},
			computed: {
				userId() {
					const user = this.currentUserData();
					const userId = user.id;
					return userId;
				},
				...mapGetters(["currentUserPermissions", "currentUser"]),
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
		});
	}
}

export default new MainSetting();
