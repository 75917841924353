//import ApiService from "@/core/services/api.service";

// action types
export const POST_EXCELDATA = "getexcel";

// mutation types
export const SET_EXCELDATA = "setexcel";

const state = {
	excels: [],
};

const getters = {
	excels(state) {
		return state.excels;
	},
};

const actions = {
	[POST_EXCELDATA](context, data) {
		return new Promise((resolve, reject) => {
			let state_data = context.state.excels;
			state_data.push(data);
			console.log({ state_data });
			context.commit(SET_EXCELDATA, state_data);
			resolve(data);
			reject(data);
			// ApiService.setHeader();
			// ApiService.get(`contact/${id}`)
			// 	.then(({ data }) => {
			// 		context.commit(SET_EXCELDATA, data);
			// 		resolve(data);
			// 	})
			// 	.catch(({ response }) => {
			// 		reject(response);
			// 	});
		});
	},
};

const mutations = {
	[SET_EXCELDATA](state, data) {
		state.excels = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
