/*
 [Ctrl + B] to focus any field when using 
 v-focus-on-key directive
 
 */
export default {
	bind(el, binding) {
		el.onKeyUp = function (event) {
			event.preventDefault();
			if (event.ctrlKey && event.key.toLowerCase() === "b") {
				const input = el.querySelector("input");
				if (input) {
					input.focus();
				}
			}
		};
		document.addEventListener("keyup", el.onKeyUp, { capture: true });
	},
	unbind(el) {
		document.removeEventListener("keyup", el.onKeyUp, { capture: true });
	},
};
