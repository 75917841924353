import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";

// action types
export const GET_USERS = "getUsers";
export const GET_REMINDER = "getReminderData";
export const SET_REMINDER = "setReminderData";
export const CLEAR_REMINDER = "clearReminderData";
export const SET_REMINDER_LOADING = "setReminderLoading";

// mutation types
export const SET_USERS = "setUsers";

const state = {
	users: [],
	loading: false,
	reminderData: {},
};

const getters = {
	gLoading(state) {
		return state.loading;
	},
	gUsers(state) {
		return state.users;
	},
	gReminder(state) {
		return state.reminderData;
	},
};

const actions = {
	[GET_REMINDER](context, id) {
		return new Promise((resolve, reject) => {
			context.commit(SET_REMINDER_LOADING, true);
			ApiService.setHeader();
			ApiService.get("single-reminder-detail/" + id)
				.then((output) => {
					const reminder = ObjectPath.get(output, "data.reminder");
					context.commit(SET_REMINDER, reminder);
					resolve(reminder);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(SET_REMINDER_LOADING, false);
				});
		});
	},
};

const mutations = {
	[CLEAR_REMINDER](state) {
		state.reminderData = {};
	},
	[SET_REMINDER_LOADING](state, payload) {
		state.loading = payload;
	},
	[SET_REMINDER](state, data) {
		state.reminderData = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
